@import "../../../styles/mixins";
@import "../../../styles/extends";

.footer {
    flex: none;
    background-color: var(--dark-background);

    &__inner {
        @extend %main-width;
        padding-inline: var(--padding-gor-size-sm);
        // padding: 1em var(--padding-gor-size-sm);
    }

    &__logo {
        display: none;
    }

    // &__columns {
    //     display: flex;
    //     gap: 1em;
    //     flex-direction: column;
    //     text-align: center;
    //     @include media(sm) {
    //         flex-direction: row;
    //         text-align: unset;
    //     }
    // }

    // &__column {
    //     // &_content_logo {
    //     //     @include media(sm) {
    //     //         flex-basis: clamp(4rem, -0.507rem + 22.5352vw, 20rem);
    //     //     }
    //     // }

    //     // &_content_menu {}
    // }

    // &__logo {
    //     block-size: clamp(4rem, 2.8732rem + 5.6338vw, 8rem);
    //     min-block-size: clamp(4rem, 2.8732rem + 5.6338vw, 8rem);
    //     max-block-size: clamp(4rem, 2.8732rem + 5.6338vw, 8rem);
    //     margin-inline: auto;

    //     @include media(sm) {
    //         margin-inline: 0;
    //     }
    // }

    // &__menu {}

    // &__menu-list {
    //     @extend %reset-list;

    //     display: flex;
    //     flex-direction: column;
    //     flex-wrap: wrap;

    //     @include media(sm) {
    //         flex-direction: row;
    //     }
    // }

    // &__menu-link {
    //     margin: 0;
    //     color: inherit;
    //     text-decoration: none;
    //     display: block;
    //     font-size: var(--text-size-md);
    //     font-weight: 600;
    //     padding: min(1.25vmax, 0.875em) min(2vmax, 1.875em);
    //     font-family: var(--font-headings);

    //     &:hover {
    //         color: var(--primary);
    //     }

    //     &_active {
    //         color: var(--primary);
    //     }
    // }

    &__content {
        padding: 1em var(--padding-gor-size-md);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 1em;
        text-align: center;
        @include media(sm) {
            text-align: unset;
        }
    }

    &__author {
        margin: 0;
        font-family: var(--font-headings);
        font-size: 0.875em;
        color: var(--dark-low);
    }

    &__lk-link {
        margin-inline: auto;
        @include media(sm) {
            margin-inline-end: 0;
        }
    }

    // &__sub-menu {}

    // &__sub-menu-caption {
    //     pointer-events: none;
    // }

    // &__sub-menu-list {
    //     @extend %reset-list;

    //     display: flex;
    //     flex-direction: column;
    // }

    // &__sub-menu-link {
    //     color: inherit;
    //     text-decoration: none;
    //     display: block;
    //     font-size: var(--text-size-md);
    //     padding: 0.5em min(2vmax, 1.875em);
    //     font-family: var(--font-headings);

    //     &:hover {
    //         color: var(--primary);
    //     }

    //     &_active {
    //         color: var(--primary);
    //     }
    // }
}
