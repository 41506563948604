@import "../styles/mixins";
@import "../styles/extends";
@import "../styles/animations";

@import "../../node_modules/@splidejs/splide/dist/css/splide.min.css";
@import "react-loading-skeleton/dist/skeleton.css";

:root {
    --font-headings: "Balsamiq Sans", cursive;
    --main-font-family: "Roboto", sans-serif;

    --main-text-color: #000;
    --dark-low: #656060;
    --primary: rgb(28, 163, 6);
    --primary-opacity: 28, 163, 6;
    --accent-light: #f5cd38;
    --dark-background: rgba(106, 105, 105, 0.1);

    --eror-color: #f7685b;
    --alert-color: #ffb946;
    --success-color: #2ed47a;
    --info-color: #109cf1;

    --main-width: 100%;

    --title-size-xxl: 2.5em; // 40
    --title-size-xl: 2em; // 32
    --title-size-lg: 1.5em; // 24
    --title-size-md: 1.5em; // 24
    --title-size-sm: 1.25em; // 20

    --caption: 1.5em; // 24

    --text-size-xl: 1.25em; // 20
    --text-size-lg: 1.125em; // 18
    --text-size-md: 1em; // 16

    --padding-gor-size-sm: 0.875rem;
    --padding-gor-size-md: 1.875rem;

    @include media(xs) {
        --title-size-xxl: clamp(2.5em, #{fs-scale(4)}, 4em); // 64
        --title-size-xl: clamp(2em, #{fs-scale(3.125)}, 3.125em); // 50
        --title-size-lg: clamp(1.5em, #{fs-scale(2.5)}, 2.5em); // 40
        --title-size-md: clamp(1.5em, #{fs-scale(2.1875)}, 2.1875em); // 35
        --title-size-sm: clamp(1.5em, #{fs-scale(1.875)}, 1.875em); // 30

        --padding-gor-size-sm: 2rem;
        --padding-gor-size-md: 5rem;
    }

    @include media(lg) {
        --main-width: 90em;
    }
}

.main-content {
    flex: auto;

    display: flex;
    flex-direction: column;
}

.main-title {
    margin: 0 0 min(2.5vmax, 2.5rem);
    font-family: var(--font-headings);
    font-size: var(--title-size-xxl);
    font-weight: normal;
    text-align: center;
}

.main-link {
    text-decoration: underline;
    text-underline-offset: 5px;

    &:hover {
        color: var(--primary);
    }
}

.more-button-link {
    display: block;
    width: max-content;
    margin: 1em auto;
    text-decoration: none;
    font-family: var(--font-headings);
    font-size: var(--text-size-xl);
    &:hover {
        color: var(--primary);
    }
}

.main-wrapper {
    @extend %main-width;
    padding: min(3.25vmax, 1.875em) var(--padding-gor-size-sm);
}

.card-link {
    block-size: 100%;
    display: flex;
    text-decoration: none;
}

@import "./blocks/article";
@import "./blocks/teachers-card";
@import "./blocks/person";
