@import "../../../styles/mixins";
@import "../../../styles/extends";

.admin-header {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0.625em 2em;
    box-sizing: border-box;
    gap: 1em;
    background-color: #fff;
    border-bottom: 1px solid #ebeff2;

    &__burger {
        margin-right: auto;

        @include media(xs) {
            display: none;
        }
    }
}
