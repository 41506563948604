.admin-form {
    display: flex;
    gap: 1em;
    flex-direction: column;

    &__two-columns {
        display: grid;
        gap: 0 1em;
        grid-template-columns: repeat(auto-fit, minmax(min(20em, 100%), 1fr));
    }

    &__title {
        margin: min(2.5vmax, 1.25rem) 0;
        padding-bottom: 0.5em;
        font-size: 1.25em;
        font-weight: 500;
        text-transform: uppercase;
        border-bottom: 2px solid rgba(var(--admin-primary-opacity-color), 0.75);
    }

    &__subtitle {
        margin: 0.5em 0;
        font-size: 1em;
        font-weight: 500;
    }

    &__section {
        display: flex;
        gap: 1em;
        flex-direction: column;
        margin: 0 0 min(3.5vmax, 1.875em);

        &_width_one-col {
            max-width: min(25em, 100%);
        }
    }

    &__controls {
        display: flex;
        gap: 0.5em;
        flex-wrap: wrap;
    }

    // &__multy-block {}

    &__label {
        margin: 0;
        display: inline-block;
        box-sizing: border-box;
        line-height: 150%;
    }

    &__photo {
        width: min(17em, 100%);
    }

    &__button {
        width: min(15em, 45%);
    }
}
