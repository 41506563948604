.person {
    display: grid;
    gap: min(1.75vmax, 1.25em);
    padding: min(1.75vmax, 1.25em);
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: box-shadow 0.35s ease-in;

    &:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.26);
    }

    @include media(xl) {
        grid-template-columns: min(20em, 100%) 1fr;
    }

    &__image {
        @extend %alt-text-on-after;
        inline-size: 100%;
        max-height: min(60vb, 37em);
        aspect-ratio: 288/419;
        justify-self: center;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
    }

    &__section {
        min-width: 0;
    }

    &__main-content {
        margin-bottom: min(1.75vmax, 1.25em);

        @include media(xl, max) {
            text-align: center;
        }
    }

    &__title {
        margin: 0 0 0.5rem;
        font-family: var(--font-headings);
        font-size: var(--title-size-sm);
    }

    &__subtitle {
        margin: 0 0 0.5rem;
        font-size: var(--text-size-xl);
        font-weight: 600;
    }

    &__link {
        font-weight: 600;
        line-height: 2;
        color: rgba(var(--primary-opacity), 0.75);
        text-underline-offset: 5px;
        text-decoration: unset;

        svg {
            @include box(1em);
            vertical-align: middle;
        }

        &:hover {
            text-decoration: underline;
            color: var(--primary);
        }
    }

    &__list {
        @extend %reset-list;
        margin-inline-start: min(1.75vmax, 1.25em);

        display: flex;
        gap: 1.25em;
        flex-direction: column;
    }

    &__item {
        display: grid;
        gap: 0 1em;
        align-items: baseline;
        justify-content: start;

        @include media(sm) {
            grid-template-columns: 17em auto;
        }
    }

    &__label {
        margin: 0;
        font-size: var(--text-size-lg);
        font-weight: normal;
    }

    &__description {
        margin: 0;
        font-size: var(--text-size-lg);
        font-weight: 500;
    }
}
