@import "../styles/mixins";
@import "../styles/extends";

:root {
    --admin-text-color: #323c47;
    --admin-text-low-color: #707683;
    --admin-primary-color: rgb(16, 156, 241);
    --admin-primary-opacity-color: 16, 156, 241;

    --admin-error-color: #f7685b;
    --admin-error-opacity-color: 247, 103, 89;
    --admin-alert-color: #ffb946;
    --admin-success-color: #2ed47a;
    --admin-info-color: #109cf1;

    --admin-grey-100-color: #c2cfe0;
    --admin-grey-200-color: #90a0b7;
    --admin-grey-300-color: #707683;
    --admin-grey-400-color: #323c47;
    --admin-grey-500-color: #192a3e;
}

.app {
    color: var(--admin-text-color);
    display: flex;
    min-block-size: 100vb;
    background-color: #f5f6f8;

    &__header {
        flex: none;
        position: sticky;
        inset-block-start: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        z-index: 1;
    }

    &__main {
        flex: auto;
        min-width: 0;

        display: flex;
        gap: 1.5em;
        flex-direction: column;
    }

    &__content {
        flex: auto;
        margin: 0 1.5em 1.5em;
        padding: 1em;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06);
    }

    &__title-block {
        display: flex;
        align-items: center;
        gap: 0.5em;

        margin: 0 0 1.875rem;
    }
    &__title {
        margin: 0;
        font-size: min(3.25vmax, 1.875em);
        font-weight: 600;
    }
}

@import "./blocks/admin-form";
@import "./blocks/admin-view-section";
@import "./blocks/admin-section";
@import "./blocks/admin-menu-constructor";
