@import "../../styles/mixins";
@import "../../styles/extends";

.not-found {
    min-height: 100vb;
    display: flex;
    padding: min(3vmax, 1em);

    &__inner {
        text-align: center;
        margin: auto;
    }

    &__title {
        display: flex;
        flex-direction: column;
        line-height: 1;
        font-size: var(--title-size-lg);
        font-family: var(--font-headings);
    }

    &__span-accent {
        font-size: clamp(80px, calc(5rem + ((1vw - 3.75px) * 5.0955)), 120px);
        text-transform: uppercase;
        color: var(--eror-color);
    }

    &__text {
        font-size: var(--text-size-lg);
    }

    &__link {
        text-decoration: unset;
        font-weight: 500;
        color: var(--info-color);
        text-underline-offset: 5px;

        &:hover {
            text-decoration: underline;
        }
        svg {
            @include box(1em);
            vertical-align: middle;
        }
    }
}
