.admin-view-section {
    &__two-columns {
        display: grid;
        gap: 0 1em;
        grid-template-columns: repeat(auto-fit, minmax(min(20em, 100%), 1fr));
    }

    // &__column {}

    &__title {
        margin: min(2.5vmax, 1.25rem) 0;
        font-size: 1.25em;
        font-weight: 500;
        text-transform: uppercase;
    }

    &__list {
        @extend %reset-list;

        display: flex;
        gap: 1.25em;
        flex-direction: column;
        margin: 0 0 min(3.5vmax, 1.875em);
    }

    &__item {
        display: grid;
        gap: 0 1em;
        align-items: baseline;
        justify-content: start;
        grid-template-columns: min(35%, 20em) auto;
    }

    &__label {
        margin: 0;
        font-size: 1em;
        font-weight: normal;
        color: var(--admin-text-low-color);
    }

    &__description {
        margin: 0;
        font-weight: 500;
    }

    &__photo {
        width: min(17em, 100%);
    }

    &__link {
        color: rgba(var(--admin-primary-opacity-color), 0.75);
        text-underline-offset: 3px;
        text-decoration: unset;

        svg {
            @include box(1em);
            vertical-align: middle;
        }

        &:hover {
            text-decoration: underline;
            color: var(--admin-primary-color);
        }
    }

    &__editor {
        max-width: 55em;
        background-color: #f5f6f8;
        padding: 1em 1.5em;
        border-radius: 5px;
        box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.06);
    }
}
