.employees-card {
    @include box(100%);
    display: grid;
    grid-template-rows: auto 1fr 1fr;
    gap: min(1.75vmax, 1.25em);
    padding: min(1.75vmax, 1.25em);
    background-color: #fff;
    outline: 2px solid var(--dark-background);
    border-radius: 5px;
    transition: box-shadow 0.35s ease-in;
    text-align: center;

    &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    }

    &__image {
        @extend %alt-text-on-after;
        aspect-ratio: 288/419;
        object-fit: cover;
        object-position: center;
        border-radius: 5px;
    }

    &__title {
        margin: 0;
        font-size: var(--text-size-xl);
        font-weight: 600;
        align-self: center;
    }

    &__subtitle {
        margin: 0;
        align-self: center;
    }
}
