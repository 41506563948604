@import "../../../styles/mixins";
@import "../../../styles/extends";

.alert-popup {
    --color-alert: #ffb946;
    --color-error: #f7685b;
    --color-success: #2ed47a;
    --color-info: #0676ed;

    position: fixed;
    inset: 0;
    @include box(100%);
    z-index: 5000;

    display: none;

    font-size: 1rem;
    line-height: 150%;

    @include media(xs) {
        padding: 2em 1em;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.6);
        animation: fadeIn 0.5s ease-in-out;
    }

    &_opened {
        display: flex;
    }

    &_content_error .alert-popup__icon {
        border-color: var(--color-error);
        color: var(--color-error);
    }

    &_content_success .alert-popup__icon {
        border-color: var(--color-success);
        color: var(--color-success);
    }

    &_content_info .alert-popup__icon {
        border-color: var(--color-info);
        color: var(--color-info);
    }

    &__container {
        width: 100%;
        height: 100%;

        padding: 1.5em 2.5em;
        background-color: #fff;

        text-align: center;
        animation: notificShow 0.35s ease-out;
        animation-delay: 0.05s;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include media(xs) {
            height: unset;
            margin: auto;
            min-width: 22em;
            width: unset;
            max-width: 30em;

            border-radius: 10px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        }
    }

    &__icon {
        margin: 0 auto 1em;
        @include box(3.875em);

        display: flex;
        align-items: center;
        justify-content: center;

        transform: translate3d(0, -10%, 0) scale(1, 0);
        animation: notificInfo 0.2s 0.15s ease-out both;

        svg {
            @include box(100%);
            transform: translate3d(0, -10%, 0) scale(1, 0);
            animation: notificInfo 0.2s 0.35s ease-out both;
        }
    }

    &__title {
        margin: 0;
        font-size: 150%;
        font-weight: bold;

        &:empty {
            display: none;
        }
    }

    &__text {
        margin: 1em 0;
        font-size: 1em;
    }

    &__controls {
        margin-top: 1em;
        display: flex;
        gap: 0.5em;

        &:empty {
            display: none;
        }
    }
}

@include keyframes(fadeIn) {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@include keyframes(PopupShow) {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@include keyframes(notificShow) {
    0% {
        opacity: 0;
        transform: translate3d(0, -10%, 0) scale(0);
    }
    80% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1.1);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}

@include keyframes(notificInfo) {
    0% {
        opacity: 0;
        transform: translate3d(0, -10%, 0) scale(1, 0);
    }
    80% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1.2, 1.2);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}
